import { styled } from '@mui/material/styles';
import { Alert, SideLogo, LoadingButton } from '@side/pantry';
import {
  Typography,
  Grid,
  Paper,
  useMediaQuery,
  type Theme,
  Link,
  Box,
} from '@mui/material';

const maxWidth = '687';

const MainContainer = styled(Grid)`
  background-color: ${(props) => props.theme.palette.background.paper};
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const LogoutNoticeContainer = styled(Alert)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${maxWidth}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  ${({ theme }) => theme.breakpoints.down('md')} {
    position: relative;
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

const LoginContainer = styled(Grid)`
  min-height: 100vh;
  justify-content: space-between;
  align-items: end;
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: space-evenly;
    align-items: center;
    width: ${maxWidth}px;
    min-height: 400px;
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin-top: ${(props) => props.theme.spacing(6)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  width: 100%;
  text-transform: uppercase;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    margin-top: ${(props) => props.theme.spacing(4)};
  }
`;

const PrivacyPolicyContainer = styled('div')`
  max-width: ${maxWidth}px;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing(3)};
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(15)};
  }
`;

const PrivacyPolicy = styled(Typography)`
  text-align: center;
  font-size: 12px;
`;

const Copyright = styled(Typography)`
  margin: 0 auto;
`;

const BottomWrapperDiv = styled('div')`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacing(10)};
`;

interface Props {
  handleLogin: (loginType: string) => void;
  loading: boolean;
  logoutStatus: string;
  hasExpiredSession: boolean;
}

export const OktaLogin = ({
  handleLogin,
  loading = false,
  logoutStatus,
  hasExpiredSession,
}: Props) => {
  const isMediumUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );

  const logoutNotice = hasExpiredSession
    ? 'Your session has expired. You will need to Sign In to proceed.'
    : 'You have been successfully logged out';

  const copyrightAndDisclaimer = (
    <BottomWrapperDiv>
      <Copyright variant="caption">
        Copyright © {new Date().getFullYear()} Side Inc.
      </Copyright>
      <PrivacyPolicyContainer>
        <PrivacyPolicy>
          Side uses tracking technology in connection with your usage of the
          Side App. By logging in and using the App, you consent to our use and
          this tracking technology in accordance with our{' '}
          <Link href="https://side.com/privacy-policy"> Privacy Policy </Link>
        </PrivacyPolicy>
      </PrivacyPolicyContainer>
    </BottomWrapperDiv>
  );

  return (
    <MainContainer container>
      {logoutStatus === 'success' && isMediumUp && (
        <LogoutNoticeContainer>{logoutNotice}</LogoutNoticeContainer>
      )}
      <Paper elevation={3}>
        <LoginContainer container>
          {logoutStatus === 'success' && !isMediumUp && (
            <LogoutNoticeContainer>{logoutNotice}</LogoutNoticeContainer>
          )}
          <Grid textAlign="center" size={12}>
            <Box width={54} mt={0} mb={2} mx="auto">
              <SideLogo mode="dark" />
            </Box>
            <Typography variant="h2" component="h1" sx={{ marginBottom: 0 }}>
              Welcome to Side
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              fontWeight="fontWeightLight"
            >
              Sign in to your account
            </Typography>
            <StyledLoadingButton
              onClick={() => handleLogin('okta')}
              loading={loading}
              size="large"
              defaultText="Sign In"
            />
            <Typography variant="body1" gutterBottom>
              <Link
                role="link"
                href="https://sideagent.okta.com/signin/forgot-password"
              >
                Forgot Password?
              </Link>
            </Typography>
          </Grid>
          {!isMediumUp && copyrightAndDisclaimer}
        </LoginContainer>
      </Paper>
      {isMediumUp && copyrightAndDisclaimer}
    </MainContainer>
  );
};
