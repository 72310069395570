import type { NextPageWithAuthOptions } from '@side/next-auth';
import { toast } from '@side/pantry';
import { Link } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { signIn, useSession } from '@side/next-auth/react';
import { LoadingFallback } from '../src/components/LoadingFallback';
import { OktaLogin } from '../src/views/okta-login';
import { AnalyticsEvent, useTrackEvent } from '../src/modules/analytics';

const defaultErrorMessage = (
  <>
    We were not able to log you in. Please try again. If you&apos;re still
    unable to login, please{' '}
    <Link href="mailto:help@sideinc.com?subject=Assistance%20required%20with%20logging%20in%20to%20the%20Side%20App">
      contact Side support.
    </Link>
  </>
);

const LoginPage: NextPageWithAuthOptions<never> = () => {
  const trackEvent = useTrackEvent();
  const { status } = useSession();
  const [loading, setLoading] = useState(false);

  const { query, push } = useRouter();
  const { logout, reason } = query;
  const logoutStatus = Array.isArray(logout)
    ? logout[0]
    : (logout ?? 'unknown');
  const hasExpiredSession = logoutStatus === 'success' && reason === 'expired';

  useEffect(() => {
    // next-auth uses the query param
    // to indicate an error occurred
    // https://next-auth.js.org/configuration/pages#sign-in-page

    if (query.error) {
      // eslint-disable-next-line no-console
      console.warn('Error on Okta login redirect', { error: query.error });
      trackEvent(AnalyticsEvent.OktaLoginFailure);
      toast('error', defaultErrorMessage);
    }
  }, [query.error, trackEvent]);

  useEffect(() => {
    if (!loading && status === 'authenticated') {
      // if the user is already logged in, redirect them to the app
      const params = new URLSearchParams(window.location.search);
      push(`/redirect?${params.toString()}`);
    }
  }, [status, push, loading]);

  const handleLogin = async () => {
    try {
      trackEvent(AnalyticsEvent.OktaLoginClick);
      signIn('okta', {
        callbackUrl: `${window.location.origin}/identity/login${window.location.search}`,
      });
      setLoading(true);
    } catch (error) {
      // TODO: [PLAT-1731] use standardized logger module in ui
      // eslint-disable-next-line no-console
      console.warn('Error logging in', { error });
      trackEvent(AnalyticsEvent.OktaLoginFailure);
      toast('error', defaultErrorMessage);
    }
  };

  // they will be redirected with the useEffect above,
  // show a loading state while we wait for the new page
  if (status === 'authenticated') return <LoadingFallback />;

  return (
    <OktaLogin
      handleLogin={handleLogin}
      loading={loading}
      logoutStatus={logoutStatus}
      hasExpiredSession={hasExpiredSession}
    />
  );
};

LoginPage.auth = {
  isPublic: true,
};

// eslint-disable-next-line import/no-default-export
export default LoginPage;
